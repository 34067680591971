import {
  ref, watch, computed,
} from '@vue/composition-api'
import store from '@/store'
import { title, dateLatin } from '@core/utils/filter'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import moment from 'moment'
import { dateFormatMoment, dateHsFormatMoment } from '@core/utils/utils'
import { round } from 'echarts/lib/util/number'

export default function useAttendancesList() {
  // Use toast
  const toast = useToast()
  const { t } = useI18nUtils()

  const refAttendancesListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'client',
      label: t('Clients.clientList.columnName'),
      sortable: true,
    },
    {
      key: 'created',
      label: t('Attendances.data.created'),
      sortable: true,
    },
    {
      key: 'plan',
      label: t('Memberships.membershipList.planName'),
      formatter: title,
      sortable: true,
    },
    {
      key: 'membership',
      label: t('Memberships.membershipList.endDateMembership'),
      sortable: true,
    },
    {
      key: 'spacer',
      label: "",
      sortable: false,
    }
  ]

  const attendancesData = ref(null)
  const filterDate = ref(0)
  const filterAttendanceType = ref(0)
  const dateStart = ref(dateFormatMoment(new Date()))
  const dateEnd = ref(dateFormatMoment(new Date()))
  const perPage = ref(10)
  const totalAttendances = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const planFilter = ref({
    discount: '',
    discount_percentage: '',
    label: 'Todos',
    status: '',
    value: null,
  })
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refAttendancesListTable.value ? refAttendancesListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAttendances.value,
    }
  })

  const planId = computed(() => (planFilter.value ? planFilter.value.value : null))
  const refetchDataAttendances = () => {
    refAttendancesListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, filterAttendanceType, planFilter, filterDate, dateEnd, dateStart], () => {
    refetchDataAttendances()
  })
  const fetchAttendances = (ctx, callback) => {
    if (searchQuery.value.length === 0 || searchQuery.value.length >= 3) {
      store
        .dispatch('app-attendance/fetchAttendances', {
          query: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          filterDate: filterDate.value,
          dateStart: dateStart.value,
          dateEnd: dateEnd.value,
          planId: planId.value,
          filterType: filterAttendanceType.value,
        })
        .then(response => {
          const { attendances, total } = response.data
          callback(attendances)
          attendancesData.value = attendances
          totalAttendances.value = total
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: error.response ? error.response.data.error : 'Error al listar las asistencias',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      callback(refAttendancesListTable.value.localItems)
    }
  }
  const titleColumn = [
    'Apellido y nombre',
    'DNI',
    'Fecha de asistencia',
    'Nombre del plan',
    'Vencimiento del plan',
  ]
  const dataExport = (attendances, header) => {
    const data = []
    if (header && header.length > 0) {
      data.push(header)
    }
    attendances.forEach(attendance => {
      const userDataArray = []
      userDataArray.push(`${attendance.client.last_name}, ${attendance.client.first_name}`)
      userDataArray.push(attendance.client.dni ?? '')
      userDataArray.push(attendance.created != null ? dateHsFormatMoment(attendance.created) : '')
      userDataArray.push(attendance.plan.name ?? '')
      userDataArray.push(attendance.membership.end != null ? dateFormatMoment(attendance.end) : '')
      data.push(userDataArray)
    })
    return data
  }

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }
  const resolveDateStatusVariant = active => {
    if (active) return 'success'
    return 'danger'
  }
  const resolveAttendancesStatusVariant = active => {
    if (active) return 'warning'
    return 'success'
  }
  const resolveAttendances = active => {
    if (active) return t('statusMemberships.active')
    return t('statusMemberships.disabled')
  }

  const resolveDateStatus = (status, expireToDay) => {
    if (status && !expireToDay) return t('statusMemberships.inactive')
    if (!status && expireToDay) return t('statusMemberships.expireToDay')
    if (status === false) return t('statusMemberships.activeDate')
    return t('statusMemberships.noMembership')
  }
  const getDate = () => moment().startOf('day')
  const formatDate = date => {
    const myMomentObject = moment(date)
    return myMomentObject.format('DD-MM-YYYY HH:mm')
  }

  const filterDateOptions = [
    { value: 0, label: 'Todas las fechas' },
    { value: 1, label: 'Fecha creación' },
    { value: 2, label: 'Fecha inicio' },
    { value: 3, label: 'Fecha vencimiento' },
  ]

  const filterTypeAttendancesOptions = [
    { value: 0, label: 'Todas las contrataciones' },
    { value: 1, label: 'Nuevas Contrataciones' },
  ]

  return {
    fetchAttendances,
    formatDate,
    getDate,
    attendancesData,
    tableColumns,
    titleColumn,
    dataExport,
    filterAttendanceType,
    perPage,
    currentPage,
    totalAttendances,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAttendancesListTable,
    filterTypeAttendancesOptions,
    resolveUserRoleVariant,
    resolveAttendances,
    resolveDateStatusVariant,
    resolveAttendancesStatusVariant,
    refetchDataAttendances,
    resolveDateStatus,

    // Extra Filters
    planFilter,
    statusFilter,
    filterDate,
    filterDateOptions,
    dateEnd,
    dateStart,
    t,
  }
}
