<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <h5>{{ t('Attendances.dateTitle') }}</h5>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Planes</label>
            <v-select
              v-model="planFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="planOptions"
              class="w-100"
              :clearable="true"
              placeholder="Filtrar por plan"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <!-- Start date -->
            <b-form-group
              :label="t('Memberships.filterDate.start')"
              label-for="startDate"
            >
              <flat-pickr
                id="startDate"
                v-model="dateStart"
                class="form-control"
                :config="{ dateFormat: 'd-m-Y'}"
                placeholder="DD-MM-YYYY"
                :disabled="false"
              />
            </b-form-group>

          </b-col>
          <b-col
            cols="12"
            md="4"
          >

            <!-- End date -->
            <b-form-group
              :label="t('Memberships.filterDate.end')"
              label-for="endDate"
            >
              <flat-pickr
                id="endDate"
                v-model="dateEnd"
                class="form-control"
                :config="{ dateFormat: 'd-m-Y'}"
                placeholder="DD-MM-YYYY"
                :disabled="false"
              />
            </b-form-group>
            <div
              v-if="!validateDate"
              class="alert-danger pl-1"
            >
              {{ t('validate.error.date') }}
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-3"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ t('Clients.clientList.showTitle') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="ml-1">{{ t('export.title') }}</label>
            <div v-if="attendancesData && attendancesData != undefined">
              <export-xls-pdf
                title-xls="XLS"
                title-pdf="PDF"
                :file-name="t('Attendances.title')"
                :title="title"
                :data-export-to-excel="dataExport(attendancesData, titleColumn)"
                :data-export-to-pdf="dataExport(attendancesData)"
                :header-data-export-to-pdf="titleColumn"
              />
            </div>

          </b-col>
          <b-col
            cols="12"
            md="4"
          >

          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">

              <b-form-input
                v-model="searchQuery"
                :maxlength="30"
                class="d-inline-block mr-1"
                :placeholder="$t('Attendances.searchPlaceholderDni')"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refAttendancesListTable"
        class="position-relative"
        :items="fetchAttendances"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="t('Memberships.message.no-membership')"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(client)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.id.toString()"
                :text="avatarText()"
                :variant="`light-${resolveUserRoleVariant(data.item.client.id)}`"
                :to="{ name: 'apps-clients-view', params: { id: data.item.client.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-clients-view', params: { id: data.item.client.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.client.last_name + ", " + data.item.client.first_name }}
            </b-link>
            <small class="text-muted">{{ data.item.client.dni }}</small>
          </b-media>
        </template>

        <!-- Column: date created -->
        <template #cell(created)="data">
          <h6 class="text-muted">
            {{ data.item.created ? formatDate(data.item.created)+' hs.': "-" }}
          </h6>
        </template>

        <!-- Column: Plan -->
        <template #cell(plan)="data">
          <h6 class="text-muted">
            {{ (data.item.plan.name) }}
          </h6>
        </template>

        <!-- Column: date end -->
        <template #cell(membership)="data">
          <b-media vertical-align="center">
            <h6 class="text-normal">
              {{ dateLatin(data.item.membership.end) }}
            </h6>
            <b-badge
              pill
              :variant="`light-${resolveAttendancesStatusVariant(data.item.membership.isExpired || data.item.membership.isExpiresToday)}`"
              class="text-capitalize"
            >
              <small>{{ resolveDateStatus(data.item.membership.isExpired, data.item.membership.isExpiresToday) }}</small>
            </b-badge>
          </b-media>
        </template>

        <!-- Column de espacio -->
        <template><small></small></template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ t('Clients.clientList.dataMeta') }}: {{ dataMeta.to }} - {{ t('total') }}: {{ totalAttendances }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalAttendances"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>

import {
  BCard, BRow, BCol, BTable, BMedia, BAvatar, BLink,
  BBadge, BPagination, BFormInput, BFormGroup, BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { onUnmounted } from '@vue/composition-api'
import { round } from 'echarts/src/util/number'
import store from '@/store'
import ExportXlsPdf from '@/layouts/components/ExportXlsPdf.vue'
import useAttendancesList from '@/views/Attendances/attendances-list/useAttendancesList'
import useAttendanceStoreModule from '@/views/Attendances/useAttendanceStoreModule'
import { avatarText, dateLatin } from '@core/utils/filter'
import { isSameOrAfter } from '@core/utils/utils'
import usePlansList from '@/views/Plans/plans-list/usePlansList'
import planStoreModule from '@/views/Plans/planStoreModule'

export default {

  components: {
    BCardBody,
    flatPickr,
    BFormGroup,
    ExportXlsPdf,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    vSelect,
  },
  props: {
  },
  computed: {
    title() {
      return `${this.t('Attendances.title')} ${this.t('Attendances.startText')}: ${this.dateStart} ${this.t('Attendances.endText')}: ${this.dateEnd}`
    },
    validateDate() {
      return isSameOrAfter(this.dateStart, this.dateEnd)
    },
  },
  mounted() {
    this.getPlans()
  },
  updated() {
  },
  methods: {
    dateLatin,
    avatarText,
    round,
  },

  setup() {
    const ATTENDANCE_APP_STORE_MODULE_NAME = 'app-attendance'
    const PLAN_APP_STORE_MODULE_NAME = 'app-plan'

    // Register module
    if (!store.hasModule(ATTENDANCE_APP_STORE_MODULE_NAME)) store.registerModule(ATTENDANCE_APP_STORE_MODULE_NAME, useAttendanceStoreModule)
    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ATTENDANCE_APP_STORE_MODULE_NAME)) store.unregisterModule(ATTENDANCE_APP_STORE_MODULE_NAME)
      if (store.hasModule(PLAN_APP_STORE_MODULE_NAME)) store.unregisterModule(PLAN_APP_STORE_MODULE_NAME)
    })

    const {
      fetchAttendances,
      formatDate,
      getDate,
      attendancesData,
      tableColumns,
      titleColumn,
      dataExport,
      filterAttendanceType,
      perPage,
      currentPage,
      totalAttendances,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAttendancesListTable,
      filterTypeAttendancesOptions,
      resolveUserRoleVariant,
      resolveAttendances,
      resolveDateStatusVariant,
      resolveAttendancesStatusVariant,
      refetchDataAttendances,
      resolveDateStatus,

      // Extra Filters
      planFilter,
      filterDate,
      filterDateOptions,
      dateEnd,
      dateStart,
      t,
    } = useAttendancesList()

    const {
      getPlans,
      planOptions,
    } = usePlansList()

    return {
      fetchAttendances,
      planOptions,
      getPlans,
      formatDate,
      getDate,
      attendancesData,
      tableColumns,
      titleColumn,
      dataExport,
      planFilter,
      filterAttendanceType,
      perPage,
      currentPage,
      totalAttendances,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAttendancesListTable,
      filterTypeAttendancesOptions,
      resolveUserRoleVariant,
      resolveAttendances,
      resolveDateStatusVariant,
      resolveAttendancesStatusVariant,
      refetchDataAttendances,
      resolveDateStatus,

      // Extra Filters
      filterDate,
      filterDateOptions,
      dateEnd,
      dateStart,
      t,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
