import useJwt from '../../auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAttendances(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .post('/attendance/search', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addAttendanceUser(ctx, id) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .post(`/attendance/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
